import { useState } from "react";
import React from "react";
import EmptyComponent from "./Empty";
import { BASE_URL } from "../utils/constants";

const Table = ({ data }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState({});

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const openModal = (images) => {
    setModalImages(images);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleImageLoad = (index) => {
    setLoadingImages((prev) => ({ ...prev, [index]: false }));
  };

  const handleImageError = (index) => {
    setLoadingImages((prev) => ({ ...prev, [index]: false }));
  };

  if (data.filter((item) => item.missions.length !== 0).length === 0) {
    return <EmptyComponent />;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Last Name</th>
            <th className="py-2 px-4 border-b">Tel</th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter((item) => item.missions.length !== 0)
            .map((item, index) => (
              <React.Fragment key={item._id}>
                <tr
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => handleRowClick(index)}
                >
                  <td className="py-2 px-4 border-b">{item.name}</td>
                  <td className="py-2 px-4 border-b">{item.lastName}</td>
                  <td className="py-2 px-4 border-b">{item.tel}</td>
                </tr>
                {expandedRow === index && (
                  <tr>
                    <td colSpan="4">
                      <div className="max-w-full overflow-x-auto">
                        <table className="min-w-max bg-gray-50 border border-gray-200">
                          <thead>
                            <tr>
                              <th className="py-2 px-4 border-b">Vehicle</th>
                              <th className="py-2 px-4 border-b">
                                Container Condition
                              </th>
                              <th className="py-2 px-4 border-b">Reparation</th>
                              <th className="py-2 px-4 border-b">
                                Cleaning Status
                              </th>
                              <th className="py-2 px-4 border-b">
                                Charge Status
                              </th>
                              <th className="py-2 px-4 border-b">
                                Travel Number
                              </th>
                              <th className="py-2 px-4 border-b">
                                Container Number
                              </th>
                              <th className="py-2 px-4 border-b">Weight</th>
                              <th className="py-2 px-4 border-b">
                                Departure Time
                              </th>
                              <th className="py-2 px-4 border-b">Entry Time</th>
                              <th className="py-2 px-4 border-b">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.missions.map((mission, missionIndex) => {
                              return (
                                <tr key={missionIndex}>
                                  <td className="py-2 px-4 border-b">
                                    {mission.vehicle}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    {mission.containerCondition}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    {mission.reparation ?? "-"}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    {mission.cleaningStatus}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    {mission.chargeStatus}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    {mission.travelNumber}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    {mission.containerNumber}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    {mission.weight}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    {mission.departureTime}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    {mission.entryTime}
                                  </td>
                                  <td className="py-2 px-4 border-b">
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openModal(
                                          [
                                            mission.imagePaths,
                                            mission.doneImagePaths,
                                          ].flat()
                                        );
                                      }}
                                      className="px-2 py-1 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600"
                                    >
                                      Preview
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
        </tbody>
      </table>

      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded w-1/2 h-2/3 ">
            <button
              onClick={closeModal}
              className="mt-4 px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
            >
              Close
            </button>
            <h2 className="text-lg font-bold mb-2">Image Preview</h2>
            <div className="flex flex-wrap w-full">
              {modalImages.map((image, index) => (
                <div key={index} className="relative w-32 h-32 m-2">
                  {loadingImages[index] !== false && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
                      <div className="w-16 h-16 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                    </div>
                  )}
                  <img
                    src={BASE_URL + "/" + image}
                    alt={`Preview ${index + 1}`}
                    className={`object-cover w-32 h-32 cursor-pointer ${
                      loadingImages[index] === false ? "block" : "hidden"
                    }`}
                    onClick={() =>
                      window.open(BASE_URL + "/" + image, "_blank")
                    }
                    onLoad={() => handleImageLoad(index)}
                    onError={() => handleImageError(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
