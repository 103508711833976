import React from "react";

const EmptyComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[80vh]">
      <img
        src="https://static.vecteezy.com/system/resources/thumbnails/022/919/630/small_2x/search-no-result-data-document-file-not-found-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg" // Replace with an actual image URL
        alt="Page Not Found"
        className="w-full md:w-full mb-2 h-full" // Responsive sizing
      />
      <p className="text-gray-500 text-xl">No Mission Found</p>
    </div>
  );
};

export default EmptyComponent;
