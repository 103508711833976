// DownloadButton.js
import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DownloadButton = ({ data }) => {
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add the title "Missions Report" at the top
    doc.setFontSize(12); // Set font size for the title
    doc.text("Missions Report", 14, 20); // Title at position (x, y)

    const tableColumnHeaders = [
      "User Name",
      "Last Name",
      "Tel",
      "Vehicle",
      "Container Condition",
      "Reparation",
      "Cleaning Status",
      "Charge Status",
      "Travel Number",
      "Container Number",
      "Weight",
      "Departure Time",
      "Entry Time",
    ];

    const tableRows = [];

    // Filter users who have missions
    data
      .filter((user) => user.missions.length > 0)
      .forEach((user) => {
        // Loop through each mission of the user
        user.missions.forEach((mission) => {
          const missionData = [
            user.name, // User name
            user.lastName, // Last name
            user.tel, // Tel
            mission.vehicle, // Vehicle
            mission.containerCondition, // Container Condition
            mission.reparation ?? "-", // Reparation (if null, display '-')
            mission.cleaningStatus, // Cleaning Status
            mission.chargeStatus, // Charge Status
            mission.travelNumber, // Travel Number
            mission.containerNumber, // Container Number
            mission.weight, // Weight
            mission.departureTime, // Departure Time
            mission.entryTime, // Entry Time
          ];
          tableRows.push(missionData);
        });
      });

    // Add the table to the PDF
    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: 30, // Adjusted to add more space between the title and the table
      theme: "grid",
      styles: {
        fontSize: 5, // Smaller font size
        cellPadding: 2, // Smaller padding between cell content
      },
      headStyles: {
        fillColor: [22, 160, 133],
      },
    });

    // Save the PDF
    doc.save("missions-report.pdf");
  };

  return (
    <button
      onClick={generatePDF}
      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
    >
      Download PDF Report
    </button>
  );
};

export default DownloadButton;
