import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import {
  FaChevronLeft,
  FaChevronRight,
  FaTasks,
  FaCheckCircle,
  FaArchive,
  FaCog,
  FaSignOutAlt,
} from "react-icons/fa"; // Import icons
import { logout } from "../utils/constants";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`flex ${
        isOpen ? "w-64" : "w-16"
      } h-screen bg-gray-800 text-white transition-all duration-300`}
    >
      <div className="relative flex flex-col items-start h-full">
        {/* Sidebar Header */}
        <div className="p-4 h-1/7 flex w-full justify-center">
          <h1
            className={`text-3xl font-bold transition-opacity ${
              isOpen ? "opacity-100" : "opacity-0"
            } duration-300`}
          >
            Trans-Alfan
          </h1>
        </div>

        {/* Sidebar Menu */}
        <div
          className={`flex flex-col w-full justify-between p-2 h-4/5 ${
            isOpen ? "items-center" : ""
          }`}
        >
          <ul className="mt-4 w-full">
            <li>
              <Link
                to="/"
                className={`flex items-center p-4 ${
                  location.pathname === "/"
                    ? "bg-blue-500 text-white"
                    : "hover:bg-gray-700"
                } ${isOpen ? "w-full" : "w-16"} transition-all duration-300`}
              >
                <FaTasks className="mr-4" />
                {isOpen && <span>In Progress Missions</span>}
              </Link>
            </li>
            <li>
              <Link
                to="/done-missions"
                className={`flex items-center p-4 ${
                  location.pathname === "/done-missions"
                    ? "bg-blue-500 text-white"
                    : "hover:bg-gray-700"
                } ${isOpen ? "w-full" : "w-16"} transition-all duration-300`}
              >
                <FaCheckCircle className="mr-4" />
                {isOpen && <span>Done Missions</span>}
              </Link>
            </li>
            <li>
              <Link
                to="/archived-missions"
                className={`flex items-center p-4 ${
                  location.pathname === "/archived-missions"
                    ? "bg-blue-500 text-white"
                    : "hover:bg-gray-700"
                } ${isOpen ? "w-full" : "w-16"} transition-all duration-300`}
              >
                <FaArchive className="mr-4" />
                {isOpen && <span>Archived Missions</span>}
              </Link>
            </li>
            <li>
              <Link
                to="/update-password"
                className={`flex items-center p-4 ${
                  location.pathname === "/update-password"
                    ? "bg-blue-500 text-white"
                    : "hover:bg-gray-700"
                } ${isOpen ? "w-full" : "w-16"} transition-all duration-300`}
              >
                <FaCog className="mr-4" />
                {isOpen && <span>Settings</span>}
              </Link>
            </li>
          </ul>

          {/* Logout Button */}
          <button
            onClick={() => logout()}
            className={`bg-blue-600 flex items-center justify-center hover:bg-blue-300 duration-700 p-2 ${
              isOpen ? "w-full" : "w-16"
            }`}
          >
            <FaSignOutAlt className="mr-2" />
            {isOpen && (
              <span className="font-bold text-md hover:text-red-500">
                Logout
              </span>
            )}
          </button>
        </div>

        {/* Chevron for toggling sidebar */}
        <div
          className="absolute top-1/2 -right-4 bg-gray-800 p-2 rounded-full cursor-pointer transform -translate-y-1/2"
          onClick={toggleSidebar}
        >
          {isOpen ? (
            <FaChevronLeft className="text-white" />
          ) : (
            <FaChevronRight className="text-white" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
